import { useCallback } from "react";
import { useNavigate } from "react-router-dom";
import { Header } from "../../../components";
import HomeNav from "../home-nav/home-nav";

const HomeHeader = (props: { className?: any }) => {
  const { className = '', ...rest} = props;
  const navigate = useNavigate();

  const onClickNav = useCallback(
    (e: any) => {
      const url = e?.target?.dataset?.url;
      navigate(url);
    },
    [navigate]
  );

  return (
    <Header
      isFixed={true}
      className={className}
      {...rest} 
      rightAction={<HomeNav onClick={onClickNav} />}
    ></Header>
  );
};

export default HomeHeader;