import React from "react";
import { Outlet } from "react-router-dom";
import Footer from "../../components/footer";
import HomeHeader from "./home-header/home-header";

const MainLayout = () => {
  return (
    <div>
      <HomeHeader />
      <Outlet />
      <Footer />
    </div>
  );
};

export default MainLayout;
