import React, { lazy, Suspense } from "react";
import { BrowserRouter, Route, Routes } from "react-router-dom";
import MainLayout from "./layout/main/mainLayout";
import TextContent from "./pages/text-content";

const Home = lazy(() => import("./pages/home/home"));
const ViewPlans = lazy(() => import("./pages/view-plans/view-plans"));
const Contact = lazy(() => import("./pages/contact/contact"));

const AppRoutes = () => {
  return (
    <Suspense fallback={<div>Loading ....</div>}>
      <BrowserRouter>
        <Routes>
          <Route path="/" element={<MainLayout />}>
            <Route index element={<Home />} />
            <Route path="/view-plans" element={<ViewPlans />} />
            <Route path="/contact" element={<Contact />} />
          </Route>
          <Route path="/terms" element={<TextContent page="terms" />} />
          <Route path="/privacy" element={<TextContent page="privacy" />} />
          <Route path="/faq" element={<TextContent page="faq" />} />
        </Routes>
      </BrowserRouter>
    </Suspense>
  );
};

export default AppRoutes;
