import { Logo, TestRoomLogo } from "../../assets/images";
import { LogoSvg } from "../../icon/logo";
import "./header.scss";

interface HeaderProps {
  rightAction?: JSX.Element;
  className?: any;
  isFixed?: boolean;
}

export const Header = ({ rightAction = null as any, className, isFixed = false }: HeaderProps) => {
  return (
    <nav className={`header ${className || ''} ${isFixed ? 'fixed': ''}`}>
      <a className="logo-container" href="/">
        <LogoSvg />
        {/* <img src={Logo} alt="Logo" /> */}
        {/* <img src={TestRoomLogo} alt="Test Room Logo" /> */}
      </a>
      <div>{rightAction}</div>
    </nav>
  );
};
