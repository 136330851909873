import { Fragment, useEffect } from "react";
import Footer from "../../components/footer";
import HomeHeader from "../../layout/main/home-header/home-header";
import style from "./text.module.scss";
import { title } from "process";

const terms = [
  {
    title: "1. Introduction",
    description: (
      <>
        Welcome to TESTROOM, a product of ANSTERRA PTY LTD (ABN: 41 643 975
        535). These Terms and Conditions ("Terms") govern your access to and use
        of our website located at{" "}
        <a href="www.testroom.com.au">www.testroom.com.au</a> ("Site") and any
        related services (collectively, the "Services"). By accessing or using
        our Site or Services, you agree to be bound by these Terms.
      </>
    ),
  },
  {
    title: "2. Eligibility",
    description: (
      <>
        There are no specific age restrictions for using our Services; however,
        we recommend that use by minors be supervised by a parent or guardian.
        By accessing or using our Services, you represent and warrant that you
        have the legal capacity to agree to these Terms.
      </>
    ),
  },
  {
    title: "3. User Registration",
    description: (
      <>
        3.1. Account Creation: To access certain features of our Services, you
        may need to create an account. You agree to provide accurate, current,
        and complete information during the registration process. <br />
        3.2. Account Responsibility: You are responsible for maintaining the
        confidentiality of your account credentials and for all activities that
        occur under your account. You agree to notify us immediately of any
        unauthorized use of your account.
      </>
    ),
  },
  {
    title: "4. User Conduct",
    description: (
      <>
        4.1. Prohibited Activities: You agree not to:
        <ul style={{ marginLeft: '2rem'}}>
          <li>Use our Services for any illegal or unauthorized purpose.</li>
          <li>
            Impersonate any person or entity or falsely state or otherwise
            misrepresent your affiliation with a person or entity.
          </li>
          <li>
            Interfere with or disrupt the operation of our Services or servers
            or networks connected to our Services.
          </li>
          <li>
            Violate any applicable local, state, national, or international law
            or regulation.
          </li>
        </ul>
        <br />
        4.2. Content Standards: You agree not to post, upload, or transmit any
        content that is unlawful, harmful, defamatory, obscene, or otherwise
        objectionable.
      </>
    ),
  },
  {
    title: "5. Intellectual Property and Copyright",
    description: (
      <>
        5.1. Ownership: All content, trademarks, and other intellectual property
        on our Site are the property of ANSTERRA PTY LTD or its licensors. You
        may not use, reproduce, or distribute any content without our prior
        written permission. <br />
        5.2. User Content: By posting or submitting content to our Site, you
        grant us a non-exclusive, royalty-free, worldwide license to use,
        reproduce, modify, and display such content in connection with our
        Services.
        <br />
        5.3. Restrictions: It is not allowed to copy, take screenshots, or share
        any of the information or content from this Site anywhere. All content
        is protected under copyright and intellectual property laws.
        <br />
      </>
    ),
  },
  {
    title: "6. Payment, Fees, and Refunds",
    description: (
      <>
        6.1. Pricing: All prices for our Services are listed on our Site and are
        subject to change without notice. <br />
        6.2. Payment: You agree to pay all fees and charges associated with your
        use of our Services in accordance with the terms and rates set forth on
        our Site. <br />
        6.3. Refunds: We offer a 100% refund if you are not satisfied with our
        Services. Refund requests must be made by email to help@ansterra.com
        within 10 days of purchase, and you must not have completed more than
        10% of the content. No refunds will be provided after 10 days from the
        date of purchase. <br />
        6.4. Non-transferable: Your use of our Services is non-transferable.
      </>
    ),
  },
  {
    title: "7. Privacy Policy and Consent",
    description: (
      <>
        7.1. Privacy: Your privacy is important to us. We collect personal
        information to personalise your experience, and we may share your
        information with third-party service providers who perform services on
        our behalf, such as payment processing, data analysis, and marketing
        assistance. These providers are contractually obligated to protect your
        information and use it only for the purposes for which it was disclosed.
        We do not store your credit card or bank account details. Payment
        processing is handled by third-party services that are PCI DSS
        compliant. Our Privacy Policy, which explains how we collect, use, and
        protect your personal information, is incorporated into these Terms by
        reference. Please review our Privacy Policy. <br />
        7.2. Consent: By using our Services, you consent to the collection and
        use of your personal information as described in our Privacy Policy.
      </>
    ),
  },
  {
    title: "8. Technology and Usage",
    description: (
      <>
        8.1. Device Compatibility: Our Services are designed to work on iPads,
        laptops, and computers. We do not support mobile devices. The
        recommended browser for using our Services is Google Chrome. <br />
        8.2. Internet Risks: By using our Site, you accept all risks associated
        with using the internet and the World Wide Web. <br />
        8.3. Cloud Services: We use cloud services and prefer to store all
        information within Australia for our production services. However, we do
        not have control over how cloud service providers manage data.
      </>
    ),
  },
  {
    title: "9. Disclaimers and Limitation of Liability",
    description: (
      <>
        9.1. Temporary Unavailability: Our Services may be temporarily
        unavailable due to maintenance or other reasons. <br />
        9.2. Disclaimers: Our Services are provided on an "as-is" and
        "as-available" basis. Our team is dedicated to delivering high-quality
        content to students and endeavours to ensure the reliability, accuracy,
        and availability of our Services. Despite our best efforts, if any
        mistakes go unnoticed, we encourage users to report any issues using the
        "Report an Issue" option, and we will address these concerns as soon as
        we can. <br />
        9.3. Limitation of Liability: To the fullest extent permitted by law,
        ANSTERRA PTY LTD, its directors, officers, employees, content providers,
        and service providers shall not be liable for any indirect, incidental,
        special, or consequential damages arising out of or in connection with
        your use of our Services.
      </>
    ),
  },
  {
    title: "10. Indemnification",
    description: (
      <>
        You agree to indemnify and hold harmless ANSTERRA PTY LTD and its
        officers, directors, employees, and agents from any claims, liabilities,
        damages, and expenses arising out of your use of our Services or your
        violation of these Terms.
      </>
    ),
  },
  {
    title: "11. Termination",
    description: (
      <>
        We reserve the right to terminate or suspend your account and access to
        our Services at our sole discretion, without notice or liability, for
        any reason, including if you breach these Terms.
      </>
    ),
  },
  {
    title: "12. Governing Law",
    description: (
      <>
        These Terms shall be governed by and construed in accordance with the
        laws of New South Wales, Australia. Any disputes arising out of or
        relating to these Terms shall be subject to the exclusive jurisdiction
        of the courts of New South Wales.
      </>
    ),
  },
  {
    title: "13. Changes to Terms",
    description: (
      <>
        We may update these Terms from time to time. We will notify you of any
        changes by posting the new Terms on our Site. Your continued use of our
        Services after the changes take effect constitutes your acceptance of
        the new Terms.
      </>
    ),
  },
  {
    title: "14. Contact Information",
    description: (
      <>
        If you have any questions or concerns about this Privacy Policy, please
        contact us at: <br /> <br />
        <address>
          ANSTERRA PTY LTD <br />
          Denham Court, <br />
          NSW, Australia - 2565 <br />
          Email: help@ansterra.com
        </address>
      </>
    ),
  },
];

const privacy = [
  {
    title: "1. Introduction",
    description: (
      <>
        This Privacy Policy explains how ANSTERRA PTY LTD ("we," "our," or "us")
        collects, uses, discloses, and protects your personal information when
        you visit or use our website{" "}
        <a href="www.testroom.com.au">www.testroom.com.au</a> ("Site") and any
        related services (collectively, the "Services"). By accessing or using
        our Services, you consent to the collection and use of your information
        as described in this Privacy Policy.
      </>
    ),
  },
  {
    title: "2. Information We Collect",
    description: (
      <>
        2.1. Personal Information: We collect personal information that you
        provide to us, including but not limited to your name, email address,
        phone number, and payment information. This information is collected
        when you register for an account, make a purchase, or contact us for
        support.
        <br />
        2.2. Usage Data: We automatically collect information about your
        interactions with our Services, including your IP address, browser type,
        device information, pages visited, and the dates and times of your
        visits.
        <br />
        2.3. Cookies and Tracking Technologies: We use cookies and similar
        tracking technologies to enhance your experience on our Site, analyse
        usage patterns, and improve our Services. You can manage your cookie
        preferences through your browser settings.
      </>
    ),
  },
  {
    title: "3. How We Use Your Information",
    description: (
      <>
        3.1. Personalisation: We use your personal information to personalise
        your experience and provide you with tailored content and
        recommendations. <br />
        3.2. Service Delivery: Your information is used to process transactions,
        provide customer support, and deliver our Services effectively.
        <br />
        3.3. Communication: We may use your contact information to send you
        updates, newsletters, marketing materials, and other communications
        related to our Services. You can opt out of receiving marketing
        communications at any time.
        <br />
        3.4. Analytics: We use analytics tools to understand how users interact
        with our Services, which helps us improve our offerings and user
        experience.
      </>
    ),
  },
  {
    title: "4. Information Sharing and Disclosure",
    description: (
      <>
        4.1. Third-Party Service Providers: We may share your information with
        third-party service providers who perform services on our behalf, such
        as payment processing, data analysis, and marketing assistance. These
        providers are contractually obligated to protect your information and
        use it only for the purposes for which it was disclosed. <br />
        4.2. Legal Compliance: We may disclose your information if required to
        do so by law or in response to valid requests by public authorities.
        <br />
        4.3. Business Transfers: In the event of a merger, acquisition, or sale
        of all or a portion of our assets, your information may be transferred
        to the new owner as part of the transaction.
      </>
    ),
  },
  {
    title: "5. Data Security",
    description: (
      <>
        We implement appropriate technical and organisational measures to
        protect your personal information from unauthorised access, use,
        alteration, or disclosure. However, no method of transmission over the
        internet or electronic storage is completely secure, and we cannot
        guarantee absolute security.
      </>
    ),
  },
  {
    title: "6. Data Storage and Retention",
    description: (
      <>
        We use cloud services to store your information, with a preference for
        storing data within Australia for our production services. However, we
        do not have control over how cloud service providers manage data. We
        retain your personal information for as long as necessary to fulfil the
        purposes outlined in this Privacy Policy, unless a longer retention
        period is required or permitted by law.
      </>
    ),
  },
  {
    title: "7. User Rights",
    description: (
      <>
        You have the right to access, correct, update, or delete your personal
        information. You may also object to or restrict our processing of your
        information. To exercise these rights, please contact us at
        help@ansterra.com.
      </>
    ),
  },
  {
    title: "8. Children's Privacy",
    description: (
      <>
        While there are no specific age restrictions for using our Services, we
        recommend that use by minors be supervised by a parent or guardian. We
        do not knowingly collect personal information from children under 13
        without verifiable parental consent.
      </>
    ),
  },
  {
    title: "9. Changes to this Privacy Policy",
    description: (
      <>
        We may update this Privacy Policy from time to time. We will notify you
        of any changes by posting the new Privacy Policy on our Site. Your
        continued use of our Services after the changes take effect constitutes
        your acceptance of the updated Privacy Policy.
      </>
    ),
  },
  {
    title: "10. Contact Information",
    description: (
      <>
        If you have any questions or concerns about this Privacy Policy, please
        contact us at: <br /> <br />
        <address>
          ANSTERRA PTY LTD <br />
          Denham Court, <br />
          NSW, Australia - 2565 <br />
          Email: help@ansterra.com
        </address>
      </>
    ),
  },
  {
    description: (
      <>
        <br />
        <hr />
        <br />
        By accessing or using our Site or Services, you acknowledge that you
        have read, understood, and agree to be bound by this Privacy Policy and
        provide your consent to the collection and use of your information as
        described herein.
      </>
    ),
  },
];

const faq = [
  {
    title: "What is TESTROOM?",
    description: `TESTROOM is an online platform dedicated to supporting NSW school students in their preparation for selective and OC tests. We offer comprehensive resources, practice courses, mock tests, and advanced data analytics to help students excel.`,
  },
  {
    title: "Who owns TESTROOM?",
    description: `TESTROOM is a product of ANSTERRA PTY LTD, ABN: 41 643 975 535, located in Denham Court, NSW, Australia - 2565.`,
  },
  {
    title: "What does the TestRoom Practice Course include?",
    description: `The TestRoom Practice Course includes access to over 7000+ questions covering 70+ topics in Thinking Skills, Mathematical Reasoning, Reading, and Writing. The questions are generated by experts with over 20 years of experience in competitive exams and come with detailed explanations, tips, and shortcut techniques.`,
  },
  {
    title: "What levels of difficulty are available in the Practice Course?",
    description: `Questions are available at five levels of difficulty. Very Easy, Easy, Medium, Hard, and Very Hard.`,
  },
  {
    title: "Is TestRoom Practice Course feature available to Subscribe?",
    description: `Yes. It is available to subscribe from July 2024. We currently support annual subscriptions only. `,
  },
  {
    title: "What does the TestRoom Mock Tests include?",
    description: `Multiple Mock Tests are allocated based on subscription. `,
  },
  {
    title: "When will the TestRoom Mock Tests be available?",
    description: `TestRoom Mock Tests will be available starting from January 2025.`,
  },
  {
    title:
      "If I subscribe to the Practice Course, do I also get access to the Mock Tests?",
    description: `No. Practice Course and Mock Tests are two different features on TestRoom, each with its own subscription fees.`,
  },
  {
    title:
      "If I subscribe to the Practice Course now, do I need to subscribe to the Mock Tests separately in January 2025 when they become available?",
    description: `Yes. Practice Course and Mock Tests are two different features on TestRoom, each with its own subscription fees.
    `,
  },
];

const contentMap: any = {
  terms: {
    title: "Terms and Conditions of Use",
    updated: "Last updated on June, 2024",
    data: terms,
  },
  privacy: {
    title: "Privacy Policy and Consent",
    updated: "Last updated on June, 2024",
    data: privacy,
  },
  faq: {
    title: "Frequently asked questions",
    updated: "Last updated on June, 2024",
    data: faq,
  },
};

const TextContent = (props: any) => {
  const { page } = props;
  const content = contentMap[page];

  const styleApplied = [
    style.container,
    page === "privacy" ? style.privacy : null,
    page === "faq" ? style.faq : null,
  ];
  let styleForHeader = null;
  if (page !== "terms") {
    styleForHeader = page === "privacy" ? style.privacy : style.faq;
  }

  useEffect(() => {
    window.scrollTo(0, 0);
  });

  return (
    <>
      <HomeHeader className={`${style.header} ${styleForHeader}`} />
      <div className={styleApplied.join(" ")}>
        <div className="d-center d-col">
          <h1>{content?.title}</h1>
          <h4>{content?.updated}</h4>
        </div>
        <div className={style.content}>
          {content?.data?.map((data: any) => (
            <Fragment key={data?.title}>
              <div className={style.title}>{data?.title}</div>
              <p className={style.desc}>{data?.description}</p>
              {data?.sub ? (
                <ul className={style.number}>
                  {data?.sub?.map((item: any, j: any) => (
                    <li key={j}>{item}</li>
                  ))}
                </ul>
              ) : null}
              {data?.subDes ? (
                <p className={style.desc}>{data?.subDes}</p>
              ) : null}
            </Fragment>
          ))}
        </div>
      </div>
      <Footer></Footer>
    </>
  );
};

export default TextContent;
