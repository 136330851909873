import { useCallback } from "react";
import { useNavigate } from "react-router-dom";
import { facebook, insta, tweet } from "../../assets/images";
import styles from "./footer.module.scss";

const links = [
  { title: "About Us", link: "/contact" },
  { title: "Privacy Policy", link: "/privacy" },
  { title: "Terms of use", link: "/terms" },
  { title: "FAQ", link: "/faq" },
];

const social = [{ icon: facebook, path: 'https://www.facebook.com/Ansterratestroom' }, { icon: insta, path: 'https://www.instagram.com/ansterratestroom/' } ];

const Footer = () => {
  const navigate = useNavigate();

  const navigateTo = useCallback((link: any) => {
    navigate(link);
  }, [navigate]);

  const handleSocialClick  = (item: any) => {
    window.open(item?.path, "_blank");
  }

  return (
    <div className={styles.footer}>
      <div className={styles.quick}>
        {links.map((i) => (
          <div onClick={() => navigateTo(i.link)} key={i.link}>{i.title}</div>
        ))}
      </div>
      <div className={styles.social}>
        {social.map((i, j) => (
          <img src={i?.icon} alt="social" key={j} onClick={() => handleSocialClick(i)} />
        ))}
      </div>
    </div>
  );
};

export default Footer;
